import { HttpErrorResponse } from '@angular/common/http';

export const matchesStatus = (error: HttpErrorResponse, status: number) =>
  error.status === status;

export interface HttpErrorHandlerStrategy {
  matches: (error: HttpErrorResponse) => boolean;

  handle(error: HttpErrorResponse): void;
}
