import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  HttpErrorHandlerStrategy,
  matchesStatus,
} from './../error-handler/http-error-handler-strategy';
import { NotificationService } from './notification.service';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private httpErrorHandlerStrategies: Array<HttpErrorHandlerStrategy> = [];

  constructor(private notificationService: NotificationService) {
    this.httpErrorHandlerStrategies.push(
      {
        matches: e => matchesStatus(e, 400),
        handle: () =>
          this.notificationService.error('ERROR.GLOBAL.BAD_REQUEST'),
      },
      {
        matches: e => matchesStatus(e, 500),
        handle: () =>
          this.notificationService.error('ERROR.GLOBAL.INTERNAL_SERVER_ERROR'),
      },
      {
        matches: e => matchesStatus(e, 401),
        handle: () => {
          Sentry.captureException(
            new Error(`UNAUTHENTICATED ACCESS ${window.location}`),
            {
              tags: {
                type: 'unauthenticated',
              },
            }
          );
          this.notificationService.error('ERROR.GLOBAL.UNAUTHENTICATED');
        },
      },
      {
        matches: e => matchesStatus(e, 403),
        handle: () => {
          Sentry.captureException(
            new Error(`UNAUTHORIZED ACCESS ${window.location}`),
            {
              tags: {
                type: 'authorization',
              },
            }
          );
          this.notificationService.error('ERROR.GLOBAL.UNAUTHORIZED');
        },
      },
      {
        matches: e => matchesStatus(e, 404),
        handle: () => this.notificationService.error('ERROR.GLOBAL.NOT_FOUND'),
      },
      {
        matches: e => matchesStatus(e, 504),
        handle: () =>
          this.notificationService.error('ERROR.GLOBAL.SERVICE_UNAVAILABLE'),
      },
      {
        matches: __ => !navigator.onLine,
        handle: () => this.notificationService.error('ERROR.GLOBAL.OFFLINE'),
      }
    );
  }

  handleError(error) {
    console.error('handleError:', error);
    this.notificationService.error('ERROR.GLOBAL.UNNOWN');
  }

  handleHttpError(httpErrorResponse: HttpErrorResponse) {
    if (!(httpErrorResponse instanceof HttpErrorResponse)) {
      throw new Error('ERROR.GLOBAL.NOT_HTTP_ERROR_RESPONSE');
    }
    if (!environment.production) {
      console.log('handleHttpError:', httpErrorResponse);
    }
    const errorHandlingStrategy = this.httpErrorHandlerStrategies.find(s =>
      s.matches(httpErrorResponse)
    );

    if (!!errorHandlingStrategy) {
      errorHandlingStrategy.handle(httpErrorResponse);
    } else {
      this.notificationService.error('ERROR.GLOBAL.UNKNOWN');
    }
  }
}
