import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  error(notificationTextTranslation: string): void {
    this.showNotification(notificationTextTranslation);
  }

  private showNotification(notificationTextTranslation: string) {
    this.toastController
      .create({
        message: this.translateService.instant(notificationTextTranslation),
        duration: 2000,
        position: 'top',
        color: 'danger',
      })
      .then(toast => toast.present());
  }
}
