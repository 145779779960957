import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  RepairMessageQueryParams,
  RepairMessageQueryParamSortBy,
  RepairMessageQueryParamSortDir,
  RepairMessageResponse,
} from 'src/app/models/repair-messages';

// TODO type what we can get in errors from BE / http service
export type FetchError = any;

export type RepairMessageFilterQueryParams = Omit<
  RepairMessageQueryParams,
  'page' | 'sort_dir' | 'sort_by'
>;

export const TicketsActions = createActionGroup({
  source: 'Tickets overview',
  events: {
    'Trigger fetch': emptyProps(),
    'Data loaded': props<{ response: RepairMessageResponse }>(),
    'Data fetching error': props<{ error: FetchError }>(),
    'Set Filters': props<{
      filtersToUpdate: RepairMessageFilterQueryParams;
    }>(),
    Search: props<{ q: string }>(),
    'clear search': emptyProps(),
    'Set sorting params': props<{
      sort_by: RepairMessageQueryParamSortBy;
      sort_dir: RepairMessageQueryParamSortDir;
    }>(),
    'Set page': props<{ page: number }>(),
    'Clear filters': emptyProps(),
    'Load stored filters': emptyProps(),
  },
});
