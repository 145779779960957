import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant.model';

@Injectable()
export class TenantService {
  tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject([]);
  readonly API_URL = environment.BASE_API_URL;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  get tenants$() {
    return combineLatest(this.loading, this.tenants);
  }

  fetchAll(): Promise<Tenant[]> {
    this.loading.next(true);

    return new Promise((resolve, reject) => {
      this.http.get<Tenant[]>(`${this.API_URL}/tenants`).subscribe(
        tenants => {
          this.tenants.next(tenants);
          this.loading.next(false);
          return resolve(tenants);
        },
        _ => reject(null)
      );
    });
  }
}
