import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Tenant } from '../models/tenant.model';

@Injectable()
export class ConfigService {
  tenant: BehaviorSubject<Tenant> = new BehaviorSubject(null);

  get storedTenantName() {
    return localStorage.getItem('TENANT_NAME');
  }

  setTenant(tenant: Tenant) {
    this.tenant.next(tenant);
    localStorage.setItem('TENANT_NAME', tenant.name);
  }
}
