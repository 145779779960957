<ion-backdrop
  [visible]="true"
  (ionBackdropTap)="close()"
></ion-backdrop>

<ion-modal
  [isOpen]="true"
  [backdropDismiss]="false"
  [showBackdrop]="false"
  #modal
  [enterAnimation]="enterAnimation"
  [leaveAnimation]="leaveAnimation"
  [class]="'drawer-layout--' + layout"
>
  <ng-template>
    <div>
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ng-template>
</ion-modal>
