import { TenantReadyGuard } from './tenant-ready.guard';
import { AuthGuard } from './auth.guard';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from 'src/common/translateLoader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { TokenService } from './services/token.service';
import { ApiService } from './services/api.service';
import { ConfigService } from './services/config.service';
import { TenantService } from './services/tenant.service';
import { AuthService } from './services/auth.service';
import { RedirectIfLoggedIn } from './redirect-if-logged-in.guard';
import { GlobalErrorHandler } from './error-handler/global-error-handler';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ticketsFeature } from './store/tickets/tickets.feature';
import { TicketsEffects } from './store/tickets/tickets.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { singleTicketFeature } from './store/single-ticket/singleTicket.feature';
import { SingleTicketEffects } from './store/single-ticket/singleTicket.effects';
import { SvgIconsModule } from '@ngneat/svg-icon';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxDatatableModule,
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(ticketsFeature),
    StoreModule.forFeature(singleTicketFeature),
    EffectsModule.forRoot([TicketsEffects, SingleTicketEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SvgIconsModule.forRoot({
      icons: [],
      sizes: {
        xs: '10px',
        sm: '18px',
        md: '16px',
        lg: '20px',
        xl: '24px',
        xxl: '30px',
      },
      defaultSize: 'md',
    }),
  ],
  providers: [
    TenantService,
    ConfigService,
    ApiService,
    TokenService,
    StatusBar,
    SplashScreen,
    FormsModule,
    ReactiveFormsModule,
    AuthGuard,
    TenantReadyGuard,
    AuthService,
    RedirectIfLoggedIn,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
