import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActionCableChannel } from '../models/action-cable.model';
import {
  RepairMessageText,
  RepairMessageTextParams,
} from '../models/repair-message-text.model';
import {
  RepairMessage,
  RepairMessageCategory,
  RepairMessageParams,
  RepairMessageQueryParams,
  RepairMessageResponse,
  RepairMessagesByDate,
  WS_RepairMessage,
  RepairMessagesFilters,
  TicketType,
} from '../models/repair-messages';
import { ActionCableService, connected } from './action-cable.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RepairMessagesService extends ApiService {
  constructor(
    injector: Injector,
    private actionCableRepository: ActionCableService
  ) {
    super(injector);
  }

  update(repairMessage: Partial<RepairMessage>): Observable<RepairMessage> {
    return this.http.put<RepairMessage>(
      `${this.API_URL}/repair_messages/${repairMessage.id}`,
      {
        repair_message: repairMessage,
      }
    );
  }

  create(repairMessage: RepairMessageParams): Observable<RepairMessage> {
    return this.http.post<RepairMessage>(`${this.API_URL}/repair_messages`, {
      repair_message: repairMessage,
    });
  }

  findAll(params: RepairMessageQueryParams): Observable<RepairMessageResponse> {
    return this.http.get<RepairMessageResponse>(
      `${this.API_URL}/repair_messages`,
      {
        params: params as any as HttpParams,
      }
    );
  }

  getExportData(): Observable<any> {
    return this.http.get(
      `${this.API_URL}/repair_messages/export_repair_messages`,
      { responseType: 'text' }
    );
  }

  getExportSingleTicketData(repairMessageId: number): Observable<any> {
    return this.http.get(
      `${this.API_URL}/repair_messages/export_single_ticket`,
      { params: { id: repairMessageId }, responseType: 'blob' }
    );
  }

  getAvailableFilters(): Observable<RepairMessagesFilters> {
    return this.http.get<RepairMessagesFilters>(
      `${this.API_URL}/repair_messages/find_all_filters`
    );
  }

  getDates(): Observable<RepairMessagesByDate[]> {
    return this.http.get<RepairMessagesByDate[]>(
      `${this.API_URL}/repair_messages/dates`
    );
  }

  getRepairMessage(id: number | string): Observable<RepairMessage> {
    return this.http.get<RepairMessage>(
      `${this.API_URL}/repair_messages/${id}`
    );
  }

  postReplyMessage(
    repairMessageText: RepairMessageTextParams
  ): Observable<RepairMessageText> {
    return this.http.post<RepairMessageText>(
      this.API_URL + '/repair_message_texts',
      {
        repair_message_text: repairMessageText,
      }
    );
  }

  getRepairMessageCategories(
    ticketType: TicketType
  ): Observable<RepairMessageCategory[]> {
    return this.http.get<RepairMessageCategory[]>(
      `${this.BASE_URL}/repair_message_categories`,
      {
        params: { ticket_type: ticketType },
      }
    );
  }

  repairMessageWSUpdates(
    repairMessageId: number
  ): Observable<WS_RepairMessage> {
    return this.actionCableRepository
      .subscribeToChannel(ActionCableChannel.RepairMessageChannel, {
        repair_message_id: repairMessageId,
      })
      .pipe(filter((x): x is WS_RepairMessage => x !== connected));
  }
}
