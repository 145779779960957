import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { RepairMessagesService } from 'src/app/services/repair-messages.service';
import { SingleTicketActions } from './singleTicket.actions';

@Injectable()
export class SingleTicketEffects {
  loadTicket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleTicketActions.loadTicket),
      concatMap(action => {
        return this.repairMessagesService.getRepairMessage(action.id).pipe(
          map(data => SingleTicketActions.ticketLoaded({ response: data })),
          catchError(error =>
            of(SingleTicketActions.ticketFetchingError({ error }))
          )
        );
      })
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleTicketActions.updateTicket),
      concatMap(params =>
        this.repairMessagesService.update(params.params).pipe(
          map(data =>
            SingleTicketActions.updatedTicketSuccess({ response: data })
          ),
          catchError(error =>
            of(SingleTicketActions.updatedTicketError({ error }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private repairMessagesService: RepairMessagesService
  ) {}
}
