import { createReducer, on, createFeature } from '@ngrx/store';
import {
  RepairMessage,
  RepairMessageQueryParams,
} from 'src/app/models/repair-messages';
import { TicketsActions } from './tickets.actions';

export interface TicketsState {
  loading: boolean;
  total_pages: number;
  total_records_count: number;
  tickets: RepairMessage[];
  errors: null;
  queryParams: RepairMessageQueryParams;
  searchParams: { q: string };
}

const initialState: TicketsState = {
  loading: true,
  total_pages: 0,
  total_records_count: 0,
  tickets: [],
  errors: null,
  searchParams: {
    q: '',
  },
  queryParams: {
    page: 1,
    sort_dir: '',
    sort_by: '',
    status: null,
    settlement_id: null,
    responsible_id: null,
    ticket_type: null,
  },
};

export const ticketsFeature = createFeature({
  name: 'tickets',
  reducer: createReducer(
    initialState,
    on(
      TicketsActions.dataLoaded,
      (state, action): TicketsState => ({
        ...state,
        loading: false,
        errors: null,
        tickets: action.response.repair_messages,
        total_pages: action.response.total_pages,
        total_records_count: action.response.total_records_count,
      })
    ),
    on(
      TicketsActions.dataFetchingError,
      (state, action): TicketsState => ({
        ...state,
        loading: false,
        errors: action.error,
      })
    ),
    on(
      TicketsActions.search,
      (state, action): TicketsState => ({
        ...state,
        loading: true,
        searchParams: {
          ...state.searchParams,
          q: action.q,
        },
      })
    ),

    on(
      TicketsActions.setFilters,
      (state, action): TicketsState => ({
        ...state,
        loading: true,
        queryParams: {
          ...state.queryParams,
          ...action.filtersToUpdate,
          page: initialState.queryParams.page,
        },
      })
    ),

    on(
      TicketsActions.setPage,
      (state, action): TicketsState => ({
        ...state,
        loading: true,
        queryParams: {
          ...state.queryParams,
          page: action.page,
        },
      })
    ),

    on(
      TicketsActions.setSortingParams,
      (state, action): TicketsState => ({
        ...state,
        loading: true,
        queryParams: {
          ...state.queryParams,
          sort_by: action.sort_by,
          sort_dir: action.sort_dir,
          page: initialState.queryParams.page,
        },
      })
    ),

    on(
      TicketsActions.clearFilters,
      (state, action): TicketsState => ({
        ...state,
        loading: true,
        queryParams: {
          ...initialState.queryParams,
        },
      })
    )
  ),
});
