import { createActionGroup, props } from '@ngrx/store';
import { RepairMessageText } from 'src/app/models/repair-message-text.model';
import {
  RepairMessage,
  RepairMessageStatus,
  RepairMessageUpdateParams,
} from 'src/app/models/repair-messages';

// TODO type what we can get in errors from BE / http service
export type FetchError = any;

export const SingleTicketActions = createActionGroup({
  source: 'Details page',
  events: {
    'Load ticket': props<{
      id: number;
    }>(),
    'Ticket loaded': props<{ response: RepairMessage }>(),
    'Ticket fetching error': props<{ error: FetchError }>(),
    'Update ticket': props<{
      params: RepairMessageUpdateParams;
    }>(),
    'Updated ticket success': props<{ response: RepairMessage }>(),
    'Updated ticket error': props<{ error: FetchError }>(),
    'New Message': props<{ message: RepairMessageText }>(),
    'Status update': props<{ newStatus: RepairMessageStatus }>(),
  },
});
