import { Injectable, Injector } from '@angular/core';
import { filter, map, first } from 'rxjs/operators';
import { Tenant } from '../models/tenant.model';
// import { UtilService } from '../../providers/util.service';
// import { User } from '../../models/user.model';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiService {
  protected API_URL: string;
  protected BASE_URL: string;
  protected http = this.injector.get<HttpClient>(HttpClient);
  protected configSvc = this.injector.get<ConfigService>(ConfigService);
  constructor(private injector: Injector) {
    this.configSvc.tenant
      .pipe(
        filter(x => x !== null),
        map(t => t as Tenant),
        map(t => t.base_url)
      )
      .subscribe(baseUrl => {
        this.API_URL = `${baseUrl}/facility_management/api/v1/`;
        this.BASE_URL = `${baseUrl}/api/v1/`;
      });
  }
}
