import { createReducer, on, createFeature } from '@ngrx/store';
import { RepairMessage, ticketTypes } from 'src/app/models/repair-messages';
import { SingleTicketActions } from './singleTicket.actions';

export interface SingleTicketState {
  loading: boolean;
  ticket: RepairMessage;
  errors: null;
}

const initialState: SingleTicketState = {
  loading: true,
  ticket: null,
  errors: null,
};

export const singleTicketFeature = createFeature({
  name: 'singleTicket',
  reducer: createReducer(
    initialState,
    on(
      SingleTicketActions.loadTicket,
      (state, _): SingleTicketState => ({
        ...state,
        loading: true,
        errors: null,
        ticket: null,
      })
    ),
    on(
      SingleTicketActions.ticketLoaded,
      (state, action): SingleTicketState => ({
        ...state,
        loading: false,
        errors: null,
        ticket: action.response,
      })
    ),
    on(
      SingleTicketActions.ticketFetchingError,
      (state, action): SingleTicketState => ({
        ...state,
        loading: false,
        errors: action.error,
      })
    ),

    on(
      SingleTicketActions.updatedTicketSuccess,
      (state, action): SingleTicketState => ({
        ...state,
        loading: false,
        errors: null,
        ticket: {
          ...state.ticket,
          status: action.response.status,
          responsible_id: action.response.responsible_id,
        },
      })
    ),
    on(
      SingleTicketActions.updatedTicketError,
      (state, action): SingleTicketState => ({
        ...state,
        loading: false,
        errors: action.error,
      })
    ),
    on(
      SingleTicketActions.newMessage,
      (state, action): SingleTicketState => ({
        ...state,
        ticket: {
          ...state.ticket,
          texts: [...state.ticket.texts, action.message],
        },
      })
    ),
    on(
      SingleTicketActions.statusUpdate,
      (state, action): SingleTicketState => ({
        ...state,
        ticket: {
          ...state.ticket,
          status: action.newStatus,
        },
      })
    )
  ),
});
