import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AuthData {
  accessToken: string;
  client: string;
  expiry: string;
  tokenType: string;
  uid: string;
}

@Injectable()
export class TokenService {
  authData: BehaviorSubject<AuthData> = new BehaviorSubject<AuthData>(null);

  private localStorage: Storage | any = {};

  constructor() {
    this.localStorage = localStorage || Storage;
    this.getAuthDataFromStorage();
  }

  getAuthHeadersFromResponse(
    data: HttpResponse<any> | HttpErrorResponse
  ): void {
    const headers = data.headers;

    const authData: AuthData = {
      accessToken: headers.get('access-token'),
      client: headers.get('client'),
      expiry: headers.get('expiry'),
      tokenType: headers.get('token-type'),
      uid: headers.get('uid'),
    };

    this.setAuthData(authData);
  }

  // Try to get auth data from storage.
  getAuthDataFromStorage(): void {
    const authData: AuthData = {
      accessToken: this.localStorage.getItem('accessToken'),
      client: this.localStorage.getItem('client'),
      expiry: this.localStorage.getItem('expiry'),
      tokenType: this.localStorage.getItem('tokenType'),
      uid: this.localStorage.getItem('uid'),
    };

    if (this.checkAuthData(authData)) {
      this.authData.next(authData);
    }
  }

  userSignedIn(): boolean {
    if (this.authData.value == null) {
      return false;
    } else {
      return true;
    }
  }

  clearAuthData() {
    this.localStorage.removeItem('accessToken');
    this.localStorage.removeItem('client');
    this.localStorage.removeItem('expiry');
    this.localStorage.removeItem('tokenType');
    this.localStorage.removeItem('uid');

    this.authData.next(null);
  }

  // Write auth data to storage
  private setAuthData(authData: AuthData): void {
    if (this.checkAuthData(authData)) {
      this.authData.next(authData);

      this.localStorage.setItem('accessToken', authData.accessToken);
      this.localStorage.setItem('client', authData.client);
      this.localStorage.setItem('expiry', authData.expiry);
      this.localStorage.setItem('tokenType', authData.tokenType);
      this.localStorage.setItem('uid', authData.uid);
    }
  }

  // Check if auth data complete and if response token is newer
  private checkAuthData(authData: AuthData): boolean {
    if (
      authData.accessToken != null &&
      authData.client != null &&
      authData.expiry != null &&
      authData.tokenType != null &&
      authData.uid != null
    ) {
      if (this.authData.value != null) {
        return authData.expiry >= this.authData.value.expiry;
      }
      return true;
    }
    return false;
  }
}
