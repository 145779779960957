import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { Consumer, createConsumer } from '@rails/actioncable';
import { from, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, retry, share, switchMap } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import {
  ActionCableChannel,
  ActionCableChannelType,
} from '../models/action-cable.model';
import { Tenant } from '../models/tenant.model';
import { ConfigService } from './config.service';
import { TokenService } from './token.service';

export const connected = Symbol();
export type Connected = typeof connected;

@Injectable({
  providedIn: 'root',
})
export class ActionCableService {
  constructor(
    private toastController: ToastController,
    private translate: TranslateService,
    private configuration: ConfigService,
    private tokenService: TokenService
  ) {}

  public subscribeToChannel<T extends ActionCableChannel>(
    channel: T,
    params: any = null
  ): Observable<Connected | ActionCableChannelType<T>> {
    return this.getWsConsumer().pipe(
      switchMap(consumer =>
        this.channelObservable<T>(channel, params, consumer)
      )
    );
  }

  /**
   * Use in pipe on observable from `subscribeToChannel` method. E.g.:
   * this.acr.subscribeToChannel(...).pipe(this.acr.defaltWsConnectionErrorHandler()).subscribe(...)
   */
  public defaltWsConnectionErrorHandler<T>(): MonoTypeOperatorFunction<T> {
    return retry({
      // retries to subscribe every time the returned observable emits => on every retry button click
      delay: () =>
        new Observable(s => {
          this.toastController
            .create({
              message: this.translate.instant(
                _('COMMON.ERRORS.WS_CONNECTION.ERROR')
              ),
              color: 'dark',
              duration: 0,
              position: 'middle',
              buttons: [
                {
                  text: this.translate.instant(
                    _('COMMON.ERRORS.WS_CONNECTION.CLOSE')
                  ),
                  handler: () => {
                    s.complete();
                  },
                },
                {
                  text: this.translate.instant(
                    _('COMMON.ERRORS.WS_CONNECTION.RETRY')
                  ),
                  icon: 'refresh-outline',
                  handler: () => {
                    s.next();
                  },
                },
              ],
            })
            .then(toast => toast.present())
            .catch(e => s.error(e));
        }),
    });
  }

  private channelObservable<T extends ActionCableChannel>(
    channel: T,
    params: any,
    consumer: Consumer
  ): Observable<Connected | ActionCableChannelType<T>> {
    return new Observable(s => {
      const acSubscription = consumer.subscriptions.create(
        { channel, ...params },
        {
          connected: () => s.next(connected),
          disconnected: () => s.error(),
          messages_list: data => s.next(data),
          received: data => s.next(data),
          rejected: () => s.error(),
        }
      );

      // unsubscribe from the websocket channel on observable unsubscribe
      return () => {
        acSubscription.unsubscribe();
      };
    });
  }

  private getWsConsumer(): Observable<Consumer> {
    return this.configuration.tenant.pipe(
      filter(x => x !== null),
      // get auth data for initialising ws connection
      switchMap((tenant: Tenant) =>
        from(this.tokenService.authData).pipe(
          map(authData => ({
            access_token: authData.accessToken,
            uid: authData.uid,
            client: authData.client,
            tenant: tenant.subdomain,
          }))
        )
      ),
      // get connected ws consumer
      switchMap(wsConnectionParams => {
        const wsConnectionParamsString = new URLSearchParams(
          wsConnectionParams
        ).toString();

        return new Observable<Consumer>(s => {
          const consumer = createConsumer(
            `${environment.websocketUrl}?${wsConnectionParamsString}`
          );
          consumer.connect();
          s.next(consumer);
          // disconnect consumer on last unsubscribe
          return () => consumer.disconnect();
        });
      }),
      // one consumer can be shared across multiple channels
      // this tracks #of subscribed channels. If we unsubscribe from all channels, consumer.disconnect() is called
      share()
    );
  }
}
