import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { tap } from 'rxjs/operators';
import { TokenService } from '../app/services/token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authData = this.tokenService.authData.value;

    if (authData) {
      const headers = {
        'access-token': authData.accessToken,
        client: authData.client,
        expiry: authData.expiry,
        'token-type': authData.tokenType,
        uid: authData.uid,
      };

      req = req.clone({
        setHeaders: headers,
      });
    }

    return next.handle(req).pipe(
      tap(
        res => this.handleResponse(res),
        err => this.handleResponse(err)
      )
    );
  }

  private handleResponse(
    res: HttpResponse<any> | HttpErrorResponse | HttpEvent<any>
  ): void {
    if (res instanceof HttpResponse || res instanceof HttpErrorResponse) {
      if (res.status === 401) {
        this.tokenService.clearAuthData();
        this.authService.goToLoginRequest.next();
        this.authService.logout();
      }

      this.tokenService.getAuthHeadersFromResponse(res);
    }
  }
}
