import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DrawerBaseComponent } from './components/drawer/drawer-base/drawer-base.component';
import { RedirectIfLoggedIn } from './redirect-if-logged-in.guard';
import { TenantReadyGuard } from './tenant-ready.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [TenantReadyGuard, AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'repair-messages/new',
    outlet: 'modal',
    component: DrawerBaseComponent,
    canActivateChild: [TenantReadyGuard, AuthGuard],
    data: { pageLayout: 'centered-modal' },
    loadChildren: () =>
      import('./pages/add-new-repair-message/add-repair-message.module').then(
        m => m.AddRepairMessagePageModule
      ),
  },
  {
    path: 'repair-messages/:id',
    outlet: 'modal',
    component: DrawerBaseComponent,
    canActivateChild: [TenantReadyGuard, AuthGuard],
    data: { pageLayout: 'drawer' },
    loadChildren: () =>
      import('./pages/repair-message-detail/repair-message-detail.module').then(
        m => m.RepairMessageDetailPageModule
      ),
  },

  {
    path: 'login',
    canActivateChild: [RedirectIfLoggedIn],
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  // default path is home
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
