import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { TenantService } from './services/tenant.service';

@Component({
  selector: 'fl-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  loginRequestSubscription: Subscription;
  constructor(
    private configSvc: ConfigService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private authService: AuthService,
    private tenantService: TenantService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.loginRequestSubscription =
        this.authService.goToLoginRequest.subscribe(() => {
          this.router.navigateByUrl('/login');
        });

      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.initTranslate();

      this.tenantService.fetchAll().then(() => {
        const tenants = this.tenantService.tenants.value;
        if (tenants.length === 1) {
          this.configSvc.setTenant(tenants[0]);
        } else {
          const storedTenant = tenants.find(
            tenant => tenant.name === this.configSvc.storedTenantName
          );
          if (storedTenant) {
            this.configSvc.setTenant(storedTenant);
          } else {
            this.router.navigateByUrl('/login');
          }
        }
      });
    });
  }

  private initTranslate(): void {
    // Set the default language for translation strings, and the current language.
    this.translateService.setDefaultLang('de');
    this.translateService.use('de'); // Set your language here
  }
}
