import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, tap } from 'rxjs';
import { RepairMessageQueryParams } from 'src/app/models/repair-messages';
import { RepairMessagesService } from 'src/app/services/repair-messages.service';
import { FILTERS_LOCALSTORAGE_KEY } from 'src/app/utils/constants';
import { SingleTicketActions } from '../single-ticket/singleTicket.actions';
import { TicketsActions } from './tickets.actions';
import { ticketsFeature } from './tickets.feature';

@Injectable()
export class TicketsEffects {
  fetchTickets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketsActions.triggerFetch),
      concatLatestFrom(() => [
        this.store.select(ticketsFeature.selectSearchParams),
        this.store.select(ticketsFeature.selectQueryParams),
      ]),
      concatMap(([_, queryParams, searchParams]) =>
        this.repairMessagesService.findAll(
          this.omitEmptyParams({ ...queryParams, ...searchParams })
        )
      ),
      map(data => TicketsActions.dataLoaded({ response: data })),
      catchError(error => of(TicketsActions.dataFetchingError({ error })))
    );
  });

  refetchTicketsWhenParamsChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TicketsActions.setFilters,
        TicketsActions.search,
        TicketsActions.clearFilters,
        TicketsActions.setPage,
        TicketsActions.setSortingParams
      ),
      map(_ => TicketsActions.triggerFetch())
    );
  });

  refetchAfterTicketUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SingleTicketActions.updatedTicketSuccess),
      map(_ => TicketsActions.triggerFetch())
    );
  });

  storeFiltersToLocalstorage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TicketsActions.setFilters, TicketsActions.clearFilters),
        tap(action => {
          if (action.type === TicketsActions.setFilters.type) {
            window.localStorage.setItem(
              FILTERS_LOCALSTORAGE_KEY,
              JSON.stringify(action.filtersToUpdate)
            );
          }

          if (action.type === TicketsActions.clearFilters.type) {
            window.localStorage.removeItem(FILTERS_LOCALSTORAGE_KEY);
          }
        })
      );
    },
    { dispatch: false }
  );

  loadStoredFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TicketsActions.loadStoredFilters),
      map(_ => {
        const filters = JSON.parse(
          window.localStorage.getItem(FILTERS_LOCALSTORAGE_KEY)
        );
        return TicketsActions.setFilters({ filtersToUpdate: filters });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private repairMessagesService: RepairMessagesService,
    private store: Store
  ) {}

  // passing to the request only params whith a value,
  // since page isn't an optional param it has to always be passed
  private omitEmptyParams(state) {
    const params: RepairMessageQueryParams = { page: 1 };
    Object.keys(state).forEach(key => {
      if (state[key]) {
        params[key] = state[key];
      }
    });
    return params;
  }
}
