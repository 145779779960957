import { Tenant } from './models/tenant.model';
import { ConfigService } from './services/config.service';
import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  UrlTree,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class TenantReadyGuard implements CanActivateChild {
  constructor(private cfgSvc: ConfigService, private router: Router) {}

  canActivateChild(
    _: ActivatedRouteSnapshot,
    __: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.cfgSvc.tenant.pipe(
      filter(x => x !== null),
      map(t => t as Tenant),
      map(t => (t ? true : this.router.parseUrl('/login')))
    );
  }
}
