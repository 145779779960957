import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationController, IonModal } from '@ionic/angular';
import { map, Subject, takeUntil } from 'rxjs';
@Component({
  templateUrl: './drawer-base.component.html',
  styleUrls: ['./drawer-base.component.scss'],
})
export class DrawerBaseComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal: IonModal;
  @ViewChild('modal', { read: ElementRef })
  modalElement: ElementRef<HTMLElement>;
  layout: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private animationCtrl: AnimationController,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  @HostListener('document:keyup.escape', ['$event']) onEscKeyUp() {
    this.close();
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(data => data.pageLayout)
      )
      .subscribe(page => {
        this.layout = page;
      });
  }

  // when closed by change of URL
  ngOnDestroy() {
    this.modal.dismiss();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;
    let start;
    let end;
    if (this.layout === 'drawer') {
      start = 'translateX(100%)';
      end = 'translateX(0%)';
    }
    if (this.layout === 'centered-modal') {
      start = 'translateY(100%)';
      end = 'translateY(0%)';
    }
    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper'))
      .duration(300)
      .fromTo('transform', start, end)
      .fromTo('opacity', '0.3', '1');

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(200)
      .addAnimation([wrapperAnimation]);
  };

  leaveAnimation = (baseEl: HTMLElement) =>
    this.enterAnimation(baseEl).direction('reverse');

  public close() {
    this.router.navigate([{ outlets: { modal: null } }], {
      queryParamsHandling: 'preserve',
    });
  }
}
