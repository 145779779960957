import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { TokenService } from './token.service';

export interface LoginRequest {
  email: string;
  password: string;
}

@Injectable()
export class AuthService extends ApiService {
  currentUserChanged: Subject<User> = new Subject<User>();
  goToLoginRequest: Subject<void> = new Subject<void>();

  private currentUser: User;
  constructor(
    private tokenService: TokenService, // protected configService: ConfigService
    injector: Injector
  ) {
    super(injector);
  }

  // admin defaults to false if no user present
  get userIsAdmin(): boolean {
    return this.currentUser ? this.currentUser.admin : false;
  }

  async login(credentials: LoginRequest): Promise<User> {
    try {
      const user = await this.loginRequest(credentials).toPromise();
      if (!this.allowTologin(user)) {
        this.logout();
        throw new Error('unauthorized');
      }
      this.setCurrentUser(user);
      return user;
    } catch (e) {
      throw e;
    }
  }

  logout(): Promise<any> {
    return Promise.all([
      this.logoutRequest(),
      this.tokenService.clearAuthData(),
    ]);
  }

  getUserInfo(): User {
    return { ...this.currentUser };
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${this.BASE_URL}/current_user`).pipe(
      tap(user => {
        this.setCurrentUser(user);
      })
    );
  }

  setTermsAccepted(email: string, settlementId: number): Observable<User> {
    return this.http.put<User>(`${this.BASE_URL}/current_user/accept_terms`, {
      email,
      settlementId,
    });
  }

  setCurrentUser(user: User): void {
    this.currentUser = { ...user };
    this.currentUserChanged.next(user);
  }

  updateCurrentUser(user: User) {
    this.setCurrentUser(user);
    return this.http.put<User>(`${this.BASE_URL}/current_user`, user);
  }

  removeCurrentUserAvatar() {
    return this.http.put<User>(`${this.BASE_URL}/current_user`, {
      remove_avatar: 1,
    });
  }

  async authValid(): Promise<boolean> {
    try {
      const user = await this.http
        .get(`${this.BASE_URL}/auth/validate_token`)
        .toPromise();
      this.setCurrentUser(user as User);
      return true;
    } catch (e) {
      return false;
    }
  }

  private loginRequest(credentials: LoginRequest): Observable<User> {
    return this.http.post<any>(`${this.BASE_URL}/auth/sign_in`, credentials);
  }

  private logoutRequest(): Promise<any> {
    return this.http.delete(`${this.BASE_URL}/auth/sign_out`).toPromise();
  }

  private allowTologin(user: User) {
    return (
      user.admin || user.facility_manager || user.staff || user.super_admin
    );
  }
}
